const Tag = (props) => {
    const { data_cy, className = '' } = props;
    return (
        <div data-cy={data_cy} className={`inline-flex items-center justify-between px-4 bg-white rounded-full cursor-pointer ${className}`}>
            <span className="text-sm">{props.text}</span>
            <div className="flex justify-center items-center h-6 w-6 bg-gray-100  rounded-full my-2 ml-2" onClick={props.onClick}>
                <svg className="h-4 text-gray-900" cxmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
    );
};

export default Tag;
