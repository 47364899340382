import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { BookmarkIcon } from '@heroicons/react/outline'

import { Score } from '../Stats'
import { AuthContext } from '../../../context/AuthContext'
import { JobContext } from '../../../context/JobContext'

const JobCard = ({ job = null, id = '', score = null, external = false }) => {
    const { user } = useContext(AuthContext);
    const { saveJob, setJobToSave, setSaveModalOpen } = useContext(JobContext);

    const onSave = () => {
        if (!user) {
            setJobToSave(id)
            setSaveModalOpen(true)
            return
        }

        saveJob(user._id, id)
    }

    return external
        ? <JobExternalCard job={job} id={id} score={score} onSave={onSave} user={user} /> 
        : <JobInternalCard job={job} id={id} score={score} onSave={onSave} user={user} />
}

const JobInternalCard = ({ job = null, id = '', score = null, onSave = () => {}, user = null }) => {
    const router = useRouter()
    const company = job.groupId || {}

    return (
        <div
            data-cy="job-card"
            className="flex flex-col justify-between sm:min-h-full bg-white rounded-md shadow-sm cursor-pointer transition duration-500 ease-in-out hover:shadow-md"
        >
            <div onClick={() => router.push(`/c/${company.title}/${id}`)}>
                <div className="relative flex justify-start items-start h-40 bg-cover bg-center rounded-t-md">
                    <Image
                        data-cy="jobcard-company-icon"
                        className="rounded-t-md"
                        layout="fill"
                        priority={true}
                        objectFit="cover"
                        src={`${process.env.AWS_URL}/${job.previewImgURL || (job.gallery && job.gallery.length) ? job.previewImgURL || job.gallery[0].Key : 'placeholders/jobcard-image.svg'}`}
                        alt={`${company.title} banner provided by otellu`}
                    />

                    <div className="flex justify-between w-full">
                        <div className="m-4 px-2 py-1 shadow-sm bg-white rounded z-10">
                            <span className="text-sm text-gray-900">{company.title}</span>
                        </div>
                    </div>
                    <div
                        className="flex justify-center items-center absolute left-5 w-14 h-14 bg-white rounded-md"
                        style={{ bottom: '-30px' }}
                    >
                        <Image
                            data-cy="jobcard-image"
                            className="object-cover rounded-t-md"
                            height={44}
                            width={44}
                            src={`${process.env.AWS_URL}/${company.icon || 'groups/icon-not-found.svg'}`}
                            alt={`${company.title} banner provided by otellu`}
                        />
                    </div>
                </div>

                {/* Score */}
                {score && (
                    <div className="flex gap-2 justify-end items-center w-full bg-gray-50 px-4 py-2">
                        <p className="text-sm text-gray-700">Match score</p>
                        <Score score={score} />
                    </div>
                )}

                {/* Content */}
                <div className={`sm:flex-grow flex flex-col justify-between px-4 ${score ? 'pt-4' : 'pt-8'} pb-4`}>
                    <h3 data-cy="jobcard-title" className="text-lg text-left leading-5 font-semibold text-gray-900">
                        {job.title}
                    </h3>
                    <p data-cy="jobcard-bio" className="text-base text-left leading-none line-clamp-3 font-normal text-gray-500 mt-2">
                        {job.shortDescription}
                    </p>
                    <div className="flex items-center space-x-2 mt-4">
                        {job.city && (
                            <div className="rounded-md p-2 bg-indigo-200">
                                <p className="text-xs text-indigo-700 leading-none font-normal">{job.city.name}</p>
                            </div>
                        )}
                        {job.workLevel &&  (
                            <div className="rounded-md p-2 bg-indigo-200">
                                <p className="text-xs text-indigo-700 leading-none font-normal">{Array.isArray(job.workLevel) ? job.workLevel[0] : job.workLevel}</p>
                            </div>
                        )}
                        {job.employmentType && (
                            <div className="rounded-md p-2 bg-indigo-200">
                                <p className="text-xs text-indigo-700 leading-none font-normal">{job.employmentType}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center px-4 py-4">
                <p className="text-xs text-gray-600">
                    Read more...
                </p>
                {user && user.savedJobs.includes(id) ? (
                    <div className="bg-indigo-700 px-1 py-1 rounded-md" onClick={onSave}>
                        <BookmarkIcon className="w-4 h-4 text-white" />
                    </div>
                ) : (
                    <div onClick={onSave}>
                        <BookmarkIcon className="w-6 h-6 text-gray-400" />
                    </div>
                )}
            </div>
        </div>
    );
};

const JobExternalCard = ({ job = null, id = '', score = null, onSave = () => {}, user = null }) => {
    const router = useRouter()
    
    return (
        <div
            data-cy="job-card"
            className="flex flex-col justify-between sm:min-h-full bg-white rounded-md shadow-sm cursor-pointer transition duration-500 ease-in-out hover:shadow-md"
        >
            <div onClick={() => job.body ? router.push(`/job/${job.slug}`) : window.open(`${job.url}?utm_source=otellu.work`, '_blank')}>
                <div className="relative flex justify-start items-start h-40 bg-cover bg-center rounded-t-md">
                    <Image
                        data-cy="jobcard-company-icon"
                        className="rounded-t-md"
                        layout="fill"
                        priority={true}
                        objectFit="cover"
                        src={job.background_image}
                        alt={`${job.company_name} banner provided by otellu`}
                    />

                    <div className="flex justify-between w-full">
                        <div className="m-4 px-2 py-1 shadow-sm bg-white rounded z-10">
                            <span className="text-sm text-gray-900">{job.company_name}</span>
                        </div>
                    </div>
                    <div
                        className="flex justify-center items-center absolute left-5 w-14 h-14 bg-white rounded-md"
                        style={{ bottom: '-30px' }}
                    >
                        <Image
                            data-cy="jobcard-image"
                            className="object-cover rounded-t-md"
                            height={44}
                            width={44}
                            src={job.icon ? job.icon : `${process.env.AWS_URL}/groups/icon-not-found.svg`}
                            alt={`${job.company_name} banner provided by otellu`}
                        />
                    </div>
                </div>

                {/* Score */}
                {score && (
                    <div className="flex gap-2 justify-end items-center w-full bg-gray-50 px-4 py-2">
                        <p className="text-sm text-gray-700">Match score</p>
                        <Score score={score} />
                    </div>
                )}

                {/* Content */}
                <div className={`sm:flex-grow flex flex-col justify-between px-4 ${score ? 'pt-4' : 'pt-8'} pb-4`}>
                    <h3 data-cy="jobcard-title" className="text-lg text-left leading-5 font-semibold text-gray-900">
                        {job.title}
                    </h3>
                    <p data-cy="jobcard-bio" className="text-base text-left leading-none line-clamp-3 font-normal text-gray-500 mt-2">
                        {job.bio}
                    </p>
                    <div className="flex items-center space-x-2 mt-4">
                        {job.city && (
                            <div className="rounded-md p-2 bg-indigo-200">
                                <p className="text-xs text-indigo-700 leading-none font-normal">{job.city}</p>
                            </div>
                        )}
                        {job.workLevel && job.workLevel.length && (
                            <div className="rounded-md p-2 bg-indigo-200">
                                <p className="text-xs text-indigo-700 leading-none font-normal">{job.workLevel[0]}</p>
                            </div>
                        )}
                        {job.employmentType && (
                            <div className="rounded-md p-2 bg-indigo-200">
                                <p className="text-xs text-indigo-700 leading-none font-normal">{job.employmentType}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center px-4 py-4">
                <p className="text-xs text-gray-600">
                    Read more...
                </p>
                {user && user.savedJobs.includes(id) ? (
                    <div className="bg-indigo-700 px-1 py-1 rounded-md" onClick={onSave}>
                        <BookmarkIcon className="w-4 h-4 text-white" />
                    </div>
                ) : (
                    <div onClick={onSave}>
                        <BookmarkIcon className="w-6 h-6 text-gray-400" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default JobCard