import React from 'react';

const Score = (props) => {
    const { score } = props;

    return (
        <div className="flex justify-center items-center w-9 h-9 rounded-full bg-indigo-300 border-4 border-indigo-100 ">
            <p className="text-xs text-gray-900 leading-6 font-bold">{score.toFixed(0)}</p>
        </div>
    );
};

export default Score;
