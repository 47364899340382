import React from 'react';
import Link from 'next/link';
import { BookmarkIcon } from '@heroicons/react/outline';
import { ButtonIcon } from '../../Atoms/Elements';
import Button from '../../Buttons';

const ApplyCard = (props) => {
    const { company, job, redirectInPlatform, handleApply, user, jobId } = props;
    return (
        <div className="p-4 rounded-md shadow-lg bg-white max-w-sm">
            <div className="mt-6">
                <div className="space-y-4">
                    <div>
                        {redirectInPlatform && (
                            <Link href={`/apply/${job._id}`}>
                                <Button text="Apply for job" theme="btn btn-purple" data_cy="apply-button" />
                            </Link>
                        )}
                        {!redirectInPlatform && <Button onClick={handleApply} text="Apply for job" theme="btn btn-purple" data_cy="apply-button" />}
                    </div>
                    {user !== null && user.savedJobs.includes(jobId) ? (
                        <ButtonIcon onClick={props.onSave} theme="btn btn-black">
                            <BookmarkIcon className="h-6 w-6 text-white" />
                            Job saved
                        </ButtonIcon>
                    ) : (
                        <ButtonIcon onClick={props.onSave} theme="btn btn-outline">
                            <BookmarkIcon className="h-6 w-6 text-gray-900" />
                            Save job
                        </ButtonIcon>
                    )}
                </div>
                <div className="mt-4 flex justify-center items-center">
                    <p className="text-center text-sm">Share job</p>
                    <div className="ml-4 flex space-x-4">
                        <a
                            href={`https://api.whatsapp.com/send?text=Hey I found this job at otellu.work: ${job.title} by ${job.groupName}. Maybe its something for you. Click on this url to view the job ${process.env.PEOPLE_WEB_URL}/c/${company.title}/${job._id}`}
                            target="_blank"
                            rel="noopener"
                        >
                            <img height="24" width="24" alt="Share on whatsapp" src="/assets/share/whatsapp.svg" />
                        </a>
                        <a
                            href={`fb-messenger://share/?link=${process.env.PEOPLE_WEB_URL}/c/${company.title}/${job._id}&app_id=123456789`}
                            target="_blank"
                            rel="noopener"
                        >
                            <img height="24" width="24" alt="Share on Messenger" src="/assets/share/messenger.svg" />
                        </a>
                        <a
                            href={`sms://&body=Hey take a look at this job ${job.title} by ${job.groupName}. Maybe its something for you. Click on this url to view the job https://otellu-frontend-git-feature-ssr.otellu.now.sh/c/${company.title}/${job._id}`}
                            target="_blank"
                            rel="noopener"
                        >
                            <img height="24" width="24" alt="Share with SMS" src="/assets/share/text-message.svg" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplyCard;
