import React, { useContext } from 'react'

import { Tag } from '../../Atoms/Search';
import { SearchContextV2 } from '../../../context/SearchContextV2'

const SearchSelectedFilters = () => {
    const searchContext = useContext(SearchContextV2)

    const hasFilter =
        searchContext.branch
        || (searchContext.job || []).length
        || searchContext.employmentType 
        || searchContext.workLevel.length
        || searchContext.city
        || searchContext.remote
        || searchContext.openToMove

    if (!hasFilter) return null

    return (
        <div className="bg-gray-50 py-2">
            <div className="container mx-auto flex items-center px-4 gap-x-4">
                <p className="text-lg font-bold text-gray-700">Current search</p>
                <div className="flex flex-wrap space-x-2 mt-1">
                    {/* Branch filter */}
                    {searchContext.branch && (
                        <Tag
                            data_cy="tag-branch"
                            className="mb-2"
                            text={searchContext.branch.name}
                            onClick={() => searchContext.setBranch(null)}
                        />
                    )}
                    {/* Job filters */}
                    {(searchContext.job || []).map((x) => (
                        <Tag
                            data_cy="tag-job"
                            key={x._id}
                            className="mb-2"
                            text={x.humanReadable}
                            onClick={() => searchContext.setJob(searchContext.job.filter(j => j._id !== x._id))}
                        />
                    ))}
                    {/* Employment type filter */}
                    {searchContext.employmentType && (
                        <Tag
                            data_cy="tag-employment-type"
                            className="mb-2"
                            text={searchContext.employmentType.title}
                            onClick={() => searchContext.setEmploymentType(null)}
                        />
                    )}
                    {/* Work level filters */}
                    {searchContext.workLevel.map((x) => (
                        <Tag
                            data_cy="tag-work-level"
                            className="mb-2"
                            text={x.name}
                            key={x.name}
                            onClick={() => searchContext.setWorkLevel(searchContext.workLevel.filter(l => l.id !== x.id))}
                        />
                    ))}
                    {/* City filter */}
                    {searchContext.city && (
                        <Tag
                            data_cy="tag-city"
                            className="mb-2"
                            text={searchContext.city.name}
                            onClick={() => searchContext.setCity(null)}
                        />
                    )}
                    {/* Remote filter */}
                    {searchContext.remote && (
                        <Tag
                            data_cy="tag-city"
                            className="mb-2"
                            text="remote"
                            onClick={() => searchContext.setRemote(false)}
                        />
                    )}
                    {/* Open to move filter */}
                    {searchContext.openToMove && (
                        <Tag
                            data_cy="tag-city"
                            className="mb-2"
                            text="Open to move"
                            onClick={() => searchContext.setOpenToMove(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default SearchSelectedFilters