import React, { useContext } from 'react';
import Link from 'next/link';
import { AuthContext } from '../../../context/AuthContext';
import { UIContext } from '../../../context/UIContext';

const MobileMenu = () => {
    const { user, logoutUser } = useContext(AuthContext);
    const { toggleMobileMenu } = useContext(UIContext);
    return (
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
            <div className="pt-3 pb-2">
                <div className="flex items-center justify-between px-4">
                    <div>
                        <img className="h-8 w-auto" src="/assets/logo/otellu-logo.svg" alt="Workflow" />
                    </div>
                    <div className="-mr-2">
                        <button
                            onClick={() => toggleMobileMenu()}
                            type="button"
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                        >
                            <span className="sr-only">Close menu</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                    <Link href="/">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            Home
                        </a>
                    </Link>
                    <Link href="/for-you">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            For you
                        </a>
                    </Link>
                    <Link href="/about">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            About
                        </a>
                    </Link>
                    <Link href="/business">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            Employer
                        </a>
                    </Link>
                </div>
            </div>
            <div className="pt-4 px-2 pb-2">
                {user === null ? (
                    <>
                        <Link href="/login">
                            <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                                Login
                            </a>
                        </Link>
                        <Link href="/signup">
                            <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                                Create account
                            </a>
                        </Link>
                    </>
                ) : (
                    <>
                        <div className="flex items-center px-5">
                            <div className="min-w-0 flex-1">
                                <div className="text-base font-medium text-gray-800 truncate">
                                    {user.firstName} {user.lastName}
                                </div>
                                <div className="text-sm font-medium text-gray-500 truncate">{user.email}</div>
                            </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                            <Link href="/profile">
                                <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                                    Your Profile
                                </a>
                            </Link>
                            <a
                                onClick={() => logoutUser()}
                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                            >
                                Sign out
                            </a>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default MobileMenu;
