import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    animate={props.mobileMenuEnabled ? 'open' : 'closed'}
    initial="closed"
    {...props}
  />
);

const Hamburger = (props) => {
  return (

    <button className="outline-none" onClick={props.toggleMobileMenu}>
      <svg width="23" height="23" viewBox="0 0 23 23">
        <Path
          mobileMenuEnabled={props.mobileMenuEnabled}
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" }
          }}
        />
        <Path
          mobileMenuEnabled={props.mobileMenuEnabled}
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 }
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          mobileMenuEnabled={props.mobileMenuEnabled}
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" }
          }}
        />
      </svg>
    </button>
  )
}

export default Hamburger;