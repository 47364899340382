const Pill = (props) => {
    const { text, active } = props;
    const activeStyle = 'bg-gray-900 text-white';
    const defaultStyle = 'bg-indigo-100 text-gray-900 hover:bg-indigo-200';
    return (
        <div
            className={`flex justify-center items-center py-2 px-4 gta-regular rounded cursor-pointer ${active ? activeStyle : defaultStyle}`}
            onClick={props.onClick}
        >
            <span className="text-base">{text}</span>
        </div>
    );
};

export default Pill;
