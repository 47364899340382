import React, { useContext } from 'react';
import Link from 'next/link';
import Button from '../../Buttons';
import { UIContext } from '../../../context/UIContext';

const StudioNavBar = (props) => {
    const { toggleStudioMenu } = useContext(UIContext);
    return (
        <div className="flex flex-col relative bg-gray-900">
            <nav className="container mx-auto flex items-center justify-between z-10 py-4 px-4 relative" id="header">
                <div className="flex items-center w-full justify-between md:w-64">
                    <Link href="/">
                        <img height="24" width="92" className="fill-current h-6 mr-2 cursor-pointer" src="/assets/brand/logo-white-trans.svg" alt="Otellu" />
                    </Link>
                    <div className="md:hidden flex">
                        <img
                            height="32"
                            width="32"
                            className="mr-4 h-8 w-8 cursor-pointer"
                            src="/assets/icons/ic_menu_24px.svg"
                            alt="dropdown"
                            onClick={() => toggleStudioMenu()}
                        />
                    </div>
                </div>

                <div className="text-base items-center justify-center flex-wrap hidden space-x-8 md:flex">
                    <Link href="/business">
                        <a className="block mt-0 lg:inline-block text-white hover:text-purple-600">Home</a>
                    </Link>
                    <Link href="/pricing">
                        <a className="block mt-0 lg:inline-block text-white hover:text-purple-600">Pricing</a>
                    </Link>
                    <Link href="/">
                        <a className="block mt-0 lg:inline-block text-white hover:text-purple-600">Employee</a>
                    </Link>
                    <Link href="/blog">
                        <a className="block mt-0 lg:inline-block text-white hover:text-purple-600">Our blog</a>
                    </Link>
                </div>
                <div className="hidden md:flex justify-end w-auto items-center">
                    <a target="_blank" href="https://studio.otellu.work/register" rel="noopener">
                        <Button theme="btn-mini btn-outline" text="Start hiring" extraclassName="mr-4" />
                    </a>
                    <a target="_blank" rel="noopener" className="text-white ml-4 hover:underline" href="https://studio.otellu.work/login">
                        Login
                    </a>
                </div>
            </nav>
        </div>
    );
};

export default StudioNavBar;
