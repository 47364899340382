import React from 'react';

const BranchCard = ({ ...rest }) => {
    return (
        <div
            onClick={rest.onClick}
            className="flex flex-col items-center gap-x-2 p-6 bg-white group hover:bg-purple-800 shadow-sm rounded-2xl cursor-pointer sm:flex-row"
        >
            {rest.icon}
            <div>
                <h5 className="text-sm text-center text-gray-900 group-hover:text-white sm:text-xl sm:text-left">{rest.name}</h5>
                <p className="text-sm text-center text-gray-500 group-hover:text-white sm:text-base sm:text-left">{rest.amount} +jobs</p>
            </div>
        </div>
    );
};

export default BranchCard;
