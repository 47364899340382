import React from 'react';
import Link from 'next/link';
import { Menu } from '@headlessui/react';
import { useRouter } from 'next/router';

import { BookmarkIcon, ShareIcon } from '@heroicons/react/outline';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/solid';
import { Button } from '../../Atoms/Elements';
import { company } from 'faker';

const JobNav = ({ ...rest }) => {
    const router = useRouter();

    return (
        <div className="bg-white shadow-sm px-4 py-4">
            <div className="max-w-7xl mx-auto flex flex-col justify-between sm:px-4 sm:flex-row">
                <div className="flex items-center gap-x-6">
                    <div className="flex items-center gap-x-2 bg-white rounded-md shadow-md px-4 py-2">
                        <img className="h-6 w-6" src={rest.companyIcon} />
                        <p className="text-sm sm:text-lg">{rest.companyName}</p>
                    </div>
                    <h3 className="text-lg sm:text-2xl">{rest.vacancy.title}</h3>
                </div>
                <div className="flex items-center mt-2 sm:space-x-4 sm:flex-row sm:items-center sm:mt-0">
                    <div className="hidden sm:block">
                        <Button onClick={rest.handleApply} colorScheme="default" size="lg">
                            Apply on job
                        </Button>
                    </div>

                    <div>
                        <Button onClick={() => router.push(`/s`)} colorScheme="whiteOutline" size="lg">
                            More relevant jobs
                        </Button>
                    </div>
                    <div className="flex items-center space-x-2 ml-4 sm:ml-0 ">
                        {rest.user !== null && rest.user.savedJobs.includes(rest.vacancy._id) ? (
                            <BookmarkIconSolid onClick={() => rest.handleSave()} className="w-6 h-6 text-gray-600 cursor-pointer" />
                        ) : (
                            <BookmarkIcon onClick={() => rest.handleSave()} className="w-6 h-6 text-gray-600 cursor-pointer" />
                        )}

                        <Menu as="div" className="relative mt-2">
                            <Menu.Button>
                                <ShareIcon className="w-6 h-6 text-gray-600" />
                            </Menu.Button>
                            <Menu.Items className="flex flex-col absolute mt-1 z-10 right-0 bg-white shadow-md divide-y-2 divide-gray-100 rounded-lg">
                                <Menu.Item className="px-6 py-2 rounded-t-lg  hover:bg-gray-200">
                                    {({ active }) => (
                                        <a
                                            className={`${active && 'bg-blue-500 text-white'} ...`}
                                            target="_blank"
                                            href={`https://api.whatsapp.com/send?text=Hey I found this job at otellu.work: ${rest.vacancy.title} by ${rest.vacancy.groupName}. Maybe its something for you. Click on this url to view the job ${process.env.PEOPLE_WEB_URL}/c/${rest.company.title}/${rest.vacancy._id}`}
                                        >
                                            <p>Whatsapp</p>
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item className="px-6 py-2  hover:bg-gray-100">
                                    {({ active }) => (
                                        <a
                                            className={`${active && 'bg-blue-500 text-white'} ...`}
                                            target="_blank"
                                            href={`fb-messenger://share/?link=${process.env.PEOPLE_WEB_URL}/c/${rest.company.title}/${rest.vacancy._id}&app_id=123456789`}
                                        >
                                            <p>Messenger</p>
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item className="px-6 py-2 rounded-b-lg hover:bg-gray-100">
                                    {({ active }) => (
                                        <a
                                            className={`${active && 'bg-blue-500 text-white'} ...`}
                                            target="_blank"
                                            href={`sms://&body=Hey take a look at this job ${rest.vacancy.title} by ${rest.vacancy.groupName}. Maybe its something for you. Click on this url to view the job https://otellu-frontend-git-feature-ssr.otellu.now.sh/c/${rest.company.title}/${rest.vacancy._id}`}
                                        >
                                            <p>SMS</p>
                                        </a>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobNav;
