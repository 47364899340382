import React, { useContext } from 'react';
import Link from 'next/link';
import { AuthContext } from '../../../context/AuthContext';
import { UIContext } from '../../../context/UIContext';
const StudioMobileMenu = (props) => {
    const { user } = useContext(AuthContext);
    const { toggleStudioMenu } = useContext(UIContext);
    return (
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
            <div className="pt-3 pb-2">
                <div className="flex items-center justify-between px-4">
                    <div>
                        <img className="h-8 w-auto" src="/assets/logo/otellu-logo.svg" alt="Workflow" />
                    </div>
                    <div className="-mr-2">
                        <button
                            onClick={() => toggleStudioMenu()}
                            type="button"
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                        >
                            <span className="sr-only">Close menu</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                    <Link href="/business">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            Home
                        </a>
                    </Link>
                    <Link href="/pricing">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            Pricing
                        </a>
                    </Link>
                    <Link href="/">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            Employee
                        </a>
                    </Link>
                    <Link href="/blog">
                        <a href="#" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                            Our blog
                        </a>
                    </Link>
                </div>
            </div>
            <div className="pt-4 pb-2">
                <>
                    <a
                        href="https://studio.otellu.work/login"
                        target="_blank"
                        className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    >
                        Login
                    </a>

                    <a
                        target="_blank"
                        href="https://studio.otellu.work/register"
                        className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    >
                        Create account
                    </a>
                </>
            </div>
        </div>
    );
};

export default StudioMobileMenu;
