import { useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

const markers = [
    {
        name: 'Michael Jordan',
        role: 'Sales manager',
        avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
        latitude: 48.864716,
        longitude: 2.349014,
        city: 'Paris',
    },
    {
        name: 'Scotty pippen',
        role: 'Marketing manager',
        avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
        latitude: 51.507351,
        longitude: -0.127758,
        city: 'London',
    },
    {
        name: 'Dennis Rodman',
        role: 'Product Designer',
        avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
        latitude: 52.520008,
        longitude: 13.404954,
        city: 'Berlin',
    },
    {
        name: 'Pelle Vlaar',
        role: 'Product Designer',
        avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
        latitude: 41.385063,
        longitude: 2.173404,
        city: 'Barcelona',
    },
    {
        name: 'Pelle Vlaar',
        role: 'Product Designer',
        avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
        latitude: 52.370216,
        longitude: 4.895168,
        city: 'Amsterdam',
    },
    {
        name: 'Pelle Vlaar',
        role: 'Product Designer',
        avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
        latitude: 59.329323,
        longitude: 18.068581,
        city: 'Stockholm',
    },
];
export default function Map({ ...rest }) {
    const [viewport, setViewport] = useState({
        width: '100%',
        height: '100%',
        latitude: 45.391,
        longitude: 8.437,
        zoom: 3.4,
    });
    return (
        <ReactMapGL
            mapStyle="mapbox://styles/otellu/ckt8nwipt01g517qwmv34te2k"
            mapboxApiAccessToken="pk.eyJ1Ijoib3RlbGx1IiwiYSI6ImNrdDhuZDQ2ZjB4YjYybnBjMjVxMXppZjEifQ.XSBTDKndkvRdzLocgjWC6A"
            {...viewport}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            height={500}
            width="100%"
            className="rounded-md"
            {...rest}
        >
            {markers.map((x) => (
                <Marker latitude={x.latitude} longitude={x.longitude} offsetLeft={-20} offsetTop={-10}>
                    <span class="flex h-4 w-4 relative">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-4 w-4 bg-indigo-500"></span>
                    </span>
                    <p className="text-xs text-gray-100">{x.city}</p>

                    {/* Person Card */}
                    {/* <div className="flex bg-white shadow-sm rounded-lg p-2 gap-x-2 items-center">
                        <img src={x.avatar} className="h-10 w-10 rounded-full object-cover" />
                        <div>
                            <p className="text-xs font-bold text-gray-900">{x.name}</p>
                            <p className="text-xs font-medium text-gray-600">{x.role}</p>
                        </div>
                    </div> */}
                </Marker>
            ))}
        </ReactMapGL>
    );
}
