import React, { useContext, useState } from 'react';
import { Switch } from '@headlessui/react';
import { RadioInput, Checkbox, Dropdown, Pill } from '../../Atoms/Search';
import { SearchContextV2 } from '../../../context/SearchContextV2';
import { UIContext } from '../../../context/UIContext';
import searchFile from '../../../helpers/searchFile';
import { Modal } from '../../Atoms/Modal';

const SearchForm = () => {
    const searchContext = useContext(SearchContextV2);
    const { toggleMobileSearch } = useContext(UIContext);
    const [smartSearchModal, setSmartSearchModal] = useState(false);

    return (
        <div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={() => toggleMobileSearch()}
                    className="sm:hidden bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none "
                >
                    <span className="sr-only">Close menu</span>
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="pb-8 space-y-6 sm:space-y-6 sm:mt-3">
                {/* BRANCH */}
                <Dropdown
                    options={searchContext.branches}
                    value={searchContext.branch}
                    isMulti={false}
                    label="Branch"
                    name="branch"
                    placeholder="Select a branch"
                    onChange={searchContext.setBranch}
                    isClearable={true}
                />

                {/* JOB */}
                <Dropdown
                    options={searchContext.jobs}
                    value={searchContext.job}
                    isMulti={true}
                    label="Job"
                    name="job"
                    placeholder="Select a job"
                    onChange={searchContext.setJob}
                    isClearable={true}
                />

                {/* EMPLOYMENT TYPE */}
                <div>
                    <div className="mb-3">
                        <label className="text-xl font-bold text-gray-900 gta-bold">Employment type</label>
                    </div>
                    <div className="grid grid-cols-2 justify-between gap-4">
                        {searchContext.employmentTypes.map((x) => {
                            const selected = searchContext.employmentType && searchContext.employmentType.id === x.id

                            return (
                                <Pill
                                    key={x.id}
                                    active={selected}
                                    onClick={() => searchContext.setEmploymentType(selected ? null : x)}
                                    text={x.title}
                                />
                            )
                        })}
                    </div>
                </div>

                {/* WORK LEVEL */}
                <div>
                    <div className="mb-4">
                        <label className="text-xl font-bold text-gray-900 gta-bold">Work level</label>
                    </div>
                    <div className="grid grid-cols-2 justify-between gap-4">
                        {searchContext.workLevels.map((x) => {
                            const selected = searchContext.workLevel.some(l => l.id === x.id)
                            return (
                                <Pill
                                    active={selected}
                                    key={x.id}
                                    onClick={() => searchContext.setWorkLevel(
                                        selected
                                            ? searchContext.workLevel.filter(l => l.id !== x.id)
                                            : [...searchContext.workLevel, x]
                                    )}
                                    text={x.name}
                                />
                            )
                        })}
                    </div>
                </div>

                {/* CITIES */}
                <div>
                    <div className="mb-4">
                        <label className="text-xl font-bold text-gray-900 gta-bold">Cities</label>
                    </div>
                    <div className="space-y-3">
                        {searchContext.cities.map((x) => {
                            const selected = searchContext.city && searchContext.city.name === x.name
                            
                            return (
                                <RadioInput
                                    data_cy={`radio-search-city-${x.name}`}
                                    checked={selected}
                                    key={x._id}
                                    onClick={(city) => searchContext.setCity(city)}
                                    id={x.name}
                                    text={x.name}
                                />
                            )
                        })}
                    </div>
                </div>
                {/* LOCATION PREFS */}
                <div>
                    <div className="mb-4">
                        <label className="text-xl font-bold text-gray-900 gta-bold">Location preferences</label>
                    </div>
                    <div className="space-y-3">
                        <Checkbox
                            id={0}
                            text="Remote"
                            onClick={() => searchContext.setRemote(!searchContext.remote)}
                            checked={searchContext.remote} 
                        />
                        <Checkbox
                            id={1}
                            text="Open to move"
                            onClick={() => searchContext.setOpenToMove(!searchContext.openToMove)}
                            checked={searchContext.openToMove}
                        />
                    </div>
                </div>

                {/* SMART SEARCH */}
                <div className="space-y-1">
                    <p onClick={() => setSmartSearchModal(true)} className="text-xs text-indigo-700 underline cursor-pointer">
                        What is smart search?
                    </p>
                    <div className="flex justify-between items-center gap-4">
                        <p>
                            Smart search <span className="text-xs">(recommended)</span>
                        </p>
                        <Switch
                            data-cy="switch-search"
                            checked={searchContext.smartSearch}
                            onChange={() => searchContext.setSmartSearch(!searchContext.smartSearch)}
                            className={`${
                                searchContext.smartSearch ? 'bg-indigo-600' : 'bg-gray-200'
                            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors ease-in-out duration-200 outline-none`}
                        >
                            <span className="sr-only">Enable notifications</span>
                            <span
                                className={`${
                                    searchContext.smartSearch ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                            />
                        </Switch>
                    </div>
                    <p className="text-sm text-gray-500 mt-2">
                        Unlike a normal search, Smart Search is powered by our algorithm and allows for fuzzy job matching, resulting in more and better search
                        results.
                    </p>
                </div>
            </div>
            <Modal isOpen={smartSearchModal} onClose={() => setSmartSearchModal(false)} modalWidth="max-w-lg">
                <div>
                    <h3 className="text-2xl text-gray-900">Smart search</h3>
                    <p className="text-base text-gray-600 mt-2">
                        Every job post on Otellu is classified by our machine learning algorithm, which is able to assign <i>multiple job titles</i> to each job
                        post, and also able to <i>assign different match scores for each job title</i>.
                    </p>
                    <h5 className="text-base font-bold text-gray-900 mt-4">This means that Smart Search is capable of:</h5>
                    <ul className="pl-4">
                        <li className="text-base text-gray-600">
                            Matching you with jobs not in a yes/no fashion, but on a spectrum from full match to no match, so you see the very best matches
                            first
                        </li>
                        <li className="text-base text-gray-600">Matching your search with multiple job titles at the same time</li>
                        <li className="text-base text-gray-600">
                            Showing you results are an approximate match to your query, so you never miss a great opportunity
                        </li>
                    </ul>
                </div>
            </Modal>
        </div>
    );
};

export default SearchForm;
