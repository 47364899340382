import React from 'react';

const Checkbox = ({ ...rest }) => {
    const { text } = rest;
    return (
        <div className="flex justify-between">
            <label htmlFor={rest.id} className="gta-regular text-lg text-gray-800">
                {text}
            </label>
            <input
                onClick={rest.onClick}
                id={rest.id}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded"
                checked={rest.checked}
            />
        </div>
    );
};

export default Checkbox;
