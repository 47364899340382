const RadioInput = (props) => {
    const { id, onClick, text, checked, data_cy } = props;

    return (
        <div className="flex justify-between">
            <label htmlFor={id} className="gta-regular text-lg text-gray-800">
                {text}
            </label>
            <input
                data-cy={data_cy}
                id={id}
                onClick={(e) => onClick(checked ? null : id)}
                type="radio"
                className="h-6 w-6  border-gray-300"
                checked={checked}
            />
        </div>
    );
};

export default RadioInput;
