import React, { useState, useContext } from 'react';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import Header from './Header';

import { SearchForm } from '../components/Molecules/Search';
import { StudioMobileMenu, MobileMenu } from '../components/Molecules/Navigation';
import Footer from './Footer';
import { Cookie } from './Banner';
import { UIContext } from '../context/UIContext';
import { AuthContext } from '../context/AuthContext';

const Layout = (props) => {
    const [cookieAccept, setCookieAccept] = useState(false);
    const { mobileSearchState, mobileMenuState, mobileStudioMenuState, toggleMobileMenu } = useContext(UIContext);
    const { user, logoutUser } = useContext(AuthContext);

    return (
        <div className="xl-container mx-auto min-h-screen flex flex-col">
            <Header content={props.content} environment={props.environment} backgroundColor={props.backgroundColor} />
            <div className={`${mobileSearchState ? 'hidden' : 'block'} sm:block`}>{props.children}</div>
            {/* {cookieAccept ? null : <Cookie onClick={() => this.handleAcceptCookie()} />} */}

            {/* MOBILE SEARCH MENU */}
            <Transition
                show={mobileSearchState}
                enter=" transform-gpu duration-150 ease-out"
                enterFrom=" translate-y-4"
                enterTo="translate-y-3"
                leave="duration-150 ease-in"
                leaveFrom="translate-y-3"
                leaveTo="translate-y-4"
                // className="z-30 fixed bottom-0 top-0 h-full max-w-none w-full overflow-hidden transition transform lg:hidden"
                className="z-30 fixed bottom-0 top-0 w-full overflow-y-scroll transition transform lg:hidden"
            >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                    <div className="pt-3 pb-2 px-4 min-h-screen">
                        <SearchForm />
                    </div>
                </div>
            </Transition>

            <Transition
                show={mobileMenuState}
                enter="transform-gpu duration-150 ease-out"
                enterFrom=" translate-y-4"
                enterTo="translate-y-3"
                leave="duration-150 ease-in"
                leaveFrom="translate-y-3"
                leaveTo="translate-y-4"
                className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top lg:hidden"
            >
                <MobileMenu />
            </Transition>
            <Transition
                show={mobileStudioMenuState}
                enter="transform-gpu duration-150 ease-out"
                enterFrom=" translate-y-4"
                enterTo="translate-y-3"
                leave="duration-150 ease-in"
                leaveFrom="translate-y-3"
                leaveTo="translate-y-4"
                className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top lg:hidden"
            >
                <StudioMobileMenu />
            </Transition>
            <Footer />
        </div>
    );
};

export default Layout;
