import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/plugins/unescaped-markup/prism-unescaped-markup.min.js';
// import 'prismjs/themes/prism-tomorrow.css';

const code = `
import ML from 'otellu-ml';
//get content
const content = "As Sales Development Representative..."
//classify content
const classification = ML.classify(content)
//send to search
ML.transferToSearch(classification);
`.trim();

const CodeSnippet = (props) => {
    const { children } = props;
    useEffect(() => {
        Prism.highlightAll();
    }, []);
    return (
        <pre className="line-numbers h-full">
            <code className="language-js">{code}</code>
        </pre>
    );
};

export default CodeSnippet;
