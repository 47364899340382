import Link from 'next/link';
import { RichText } from 'prismic-reactjs';
import { client } from '../prismic-configuration';
import { searchQueries } from '../helpers/search';

const Footer = (props) => {
    const branchList = searchQueries.filter((x) => !x.queryProps.job);
    const jobList = searchQueries.filter((x) => x.queryProps.job);

    return (
        <div className="bg-gray-900">
            <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="xl:col-span-1">
                        <img height="24" width="92" className="h-6" src="/assets/brand/logo-white-trans.svg" alt="Company name" />
                        <p className="mt-8 text-white text-base leading-6">Make your future work.</p>
                        <div className="mt-8 flex">
                            <a href="https://www.facebook.com/otellu.community/" target="_blank" rel="noopener" className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Facebook</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    <path
                                        fillRule="evenodd"
                                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                            <a
                                href="https://www.instagram.com/otellu.community/"
                                target="_blank"
                                rel="noopener"
                                className="ml-6 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">Instagram</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                    <path
                                        fillRule="evenodd"
                                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <label className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">For job seekers</label>
                                <ul className="mt-4 list-none">
                                    <li>
                                        <Link href="/">
                                            <a href="#" className="text-base leading-6 text-gray-400 hover:text-white">
                                                Home
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="mt-4">
                                        <Link href="/" login>
                                            <a href="#" className="text-base leading-6 text-gray-400 hover:text-white">
                                                Login
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="mt-4">
                                        <Link href="/signup">
                                            <a href="#" className="text-base leading-6 text-gray-400 hover:text-white">
                                                Create account
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <label className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">For employers</label>
                                <ul className="mt-4 list-none">
                                    <li>
                                        <Link href="/business">
                                            <a href="#" className="text-base leading-6 text-gray-400 hover:text-white">
                                                Home
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="mt-4">
                                        <Link href="/pricing">
                                            <a href="#" className="text-base leading-6 text-gray-400 hover:text-white">
                                                Pricing
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="mt-4">
                                        <a
                                            target="_blank"
                                            rel="noopener"
                                            href="https://studio.otellu.work/register"
                                            className="text-base leading-6 text-gray-400 hover:text-white"
                                        >
                                            Create account
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <label className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">About us</label>
                                <ul className="mt-4 list-none">
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noopener"
                                            href="https://api.whatsapp.com/send?phone=+31630914938&text=I have a question about Otellu"
                                            className="text-base leading-6 text-gray-400 hover:text-white"
                                        >
                                            Whatsapp us
                                        </a>
                                    </li>
                                    <li className="mt-4">
                                        <Link href="/terms">
                                            <a href="#" className="text-base leading-6 text-gray-400 hover:text-white">
                                                Terms
                                            </a>
                                        </Link>
                                    </li>
                                    <li className="mt-4">
                                        <Link href="/policy">
                                            <a href="#" className="text-base leading-6 text-gray-400 hover:text-white">
                                                Privacy policy
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/*TODO: Get branches & jobtitles from db */}
                {/* <div className="xl:grid xl:grid-cols-3 xl:gap-8 mt-12">
                    <div className="">
                        <label className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">Branches</label>
                        <ul className="mt-4 list-none">
                            {branchList
                                .filter((x) => !x.hideInFooter)
                                .map((x) => (
                                    <li>
                                        <Link href={`/jobs/${x.alternatives[0]}`}>
                                            <p className="text-base leading-6 text-gray-400 cursor-pointer hover:text-white">{x.queryProps.branch.name}</p>
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col-start-2 col-end-4">
                        <label className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">Job categories</label>
                        <ul className="mt-4 list-none grid grid-cols-2">
                            {jobList
                                .filter((x) => !x.hideInFooter)
                                .map((x) => (
                                    <li>
                                        <Link href={`/jobs/${x.alternatives[0]}`}>
                                            <p className="text-base leading-6 text-gray-400 cursor-pointer hover:text-white">{x.queryProps.job.name}</p>
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div> */}
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base leading-6 text-gray-400 xl:text-center">&copy; 2021 Otellu BV, All rights reserved.</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;

export async function getServerSideProps(context) {
    const static_content = await client.getByUID('static', 'static', { lang: 'en-US' });
    return {
        props: {
            static_content,
        },
    };
}
