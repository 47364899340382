import Select, { components } from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: '3px solid #EEF0F4',
        borderRadius: '4px',
        backgroundColor: state.isSelected ? 'green' : 'white',
        // color: state.isSelected ? 'red' : 'blue',
        // padding: 20,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#A0AEC0',
        fontSize: '16px',
    }),
};

const Dropdown = (props) => {
    return (
        <div>
            <div className="mb-1">
                <label className="text-xl font-bold text-gray-900 gta-bold">{props.label}</label>
            </div>
            <Select
                id={`select-${props.name}`}
                name={props.name}
                options={props.options}
                value={props.value}
                styles={customStyles}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                getOptionValue={(option) => option.humanReadable || option.name}
                getOptionLabel={(option) => option.humanReadable || option.name}
                onChange={props.onChange}
                isSearchable={true}
                isClearable={props.isClearable}
            />
        </div>
    );
};

export default Dropdown;
