import React, { useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalHeader } from '../../Atoms/Modal';
import { Button } from '../../Atoms/Elements';

import { Alert } from '../../Atoms/Notications';
import { Input, Label, TextArea } from '../../Atoms/Form';

const StartupPlanForm = (props) => {
    const [reqStatus, setReqStatus] = useState({
        loading: false,
        error: false,
        success: false,
    });
    return (
        <>
            <ModalHeader
                title="Learn more about early bird plan"
                description="Thank you for your interest in our platform. Fill in your details below and we'll contact you asap. "
            />
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    company: '',
                    company_size: '',
                    message: '',
                }}
                onSubmit={async (values) => {
                    setReqStatus({
                        loading: true,
                        error: false,
                        success: false,
                    });
                    try {
                        await axios.post('/api/hubspot/startup', values);
                        setReqStatus({
                            loading: false,
                            error: false,
                            success: true,
                        });
                    } catch (err) {
                        setReqStatus({
                            loading: false,
                            error: true,
                            success: false,
                        });
                        return err;
                    }
                }}
            >
                {({ values, handleSubmit, handleChange }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-4">
                            <div>
                                <Label>First Name</Label>
                                <Input name="firstName" type="text" placeholder="Elon" required={true} onChange={handleChange} />
                            </div>
                            <div>
                                <Label>Last Name</Label>
                                <Input name="lastName" type="text" placeholder="Musk" required={true} onChange={handleChange} />
                            </div>
                            <div>
                                <Label>Email</Label>
                                <Input name="email" type="email" placeholder="elon.musk@gmail.com" required={true} onChange={handleChange} />
                            </div>

                            <div>
                                <Label>Company</Label>
                                <Input name="company" type="text" placeholder="Tesla" required={true} onChange={handleChange} />
                            </div>
                            <div>
                                <Label>Company size</Label>
                                <Input name="company_size" type="number" placeholder="10" required={true} onChange={handleChange} />
                            </div>
                            <div>
                                <Label>Message</Label>
                                <TextArea name="message" onChange={handleChange} />
                            </div>
                            <Button type="submit" colorScheme="default" size="lg" loading={reqStatus.loading}>
                                Submit
                            </Button>
                            {!reqStatus.loading && reqStatus.success && (
                                <Alert status="success" message="Thanks for submutting the form. We will contact you soon!" />
                            )}
                            {!reqStatus.loading && reqStatus.error && <Alert status="error" message="Something went wrong submitting the form. Try again!" />}
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default StartupPlanForm;
