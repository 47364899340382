import React, { useContext } from 'react';
import Link from 'next/link';
import { Transition, Menu } from '@headlessui/react';
// import Button from '../../Buttons';
import { Button } from '../../Atoms/Elements';
import { AuthContext } from '../../../context/AuthContext';
import { UIContext } from '../../../context/UIContext';

const Navbar = (props) => {
    const { user, logoutUser } = useContext(AuthContext);
    const { toggleMobileMenu, toggleMobileSearch } = useContext(UIContext);

    return (
        <div className="max-w-9xl">
            <nav className={`container flex items-center justify-between mx-auto ${props.backgroundColor} z-10 py-4 px-4 relative sm:h-auto`} id="header">
                <div className="flex items-center w-full justify-between md:w-auto">
                    <Link href="/">
                        <img height="24" width="92" className="fill-current h-6 mr-2 cursor-pointer" src="/assets/brand/logo-purple-trans.svg" alt="Otellu" />
                    </Link>
                    <div className="md:hidden flex">
                        <img
                            height="32"
                            width="32"
                            className="mr-4 h-8 w-8"
                            src="/assets/icons/ic-search-black.svg"
                            alt="dropdown"
                            onClick={() => toggleMobileSearch()}
                        />
                        <img
                            height="32"
                            width="32"
                            className="h-8 w-8 cursor-pointer"
                            src="/assets/icons/ic_menu_black_24px.svg"
                            alt="dropdown"
                            onClick={() => toggleMobileMenu()}
                        />
                    </div>
                    <div className="ml-8 text-base items-center justify-center flex-wrap hidden md:flex">
                        <Link href="/s">
                            <div className="flex mr-8 cursor-pointer relative">
                                <p className="gta-medium text-base block mt-0 lg:inline-block text-gray-700 hover:text-purple-600 ">Explore jobs</p>
                            </div>
                        </Link>
                        <Link href="/for-you">
                            <div className="flex mr-8 cursor-pointer relative">
                                <p className="gta-medium text-base block mt-0 lg:inline-block text-gray-700 hover:text-purple-600 ">For you</p>
                            </div>
                        </Link>
                        <Link href="/about">
                            <p className="gta-medium text-base mt-0  text-gray-800 cursor-pointer lg:inline-block hover:text-purple-600 mr-8">About</p>
                        </Link>
                        <Link href="/business">
                            <p className="gta-medium text-base mt-0  text-gray-800 cursor-pointer lg:inline-block hover:text-purple-600">Employer</p>
                        </Link>
                    </div>
                </div>

                {user === null ? (
                    <div className="hidden md:flex justify-end w-auto">
                        <>
                            <Link href="/signup">
                                {/* <Button className="py-3" text="Create account" theme="btn-mini btn-black" /> */}
                                <Button colorScheme="purple" size="xl">
                                    Create account
                                </Button>
                            </Link>
                            <div className="ml-4">
                                <Link href="/login">
                                    {/* <Button text="Login" theme="btn-mini btn-outline" extraclassName="ml-4" /> */}
                                    <Button colorScheme="whiteOutline" size="xl">
                                        Login
                                    </Button>
                                </Link>
                            </div>
                        </>
                    </div>
                ) : (
                    <div className="hidden md:flex justify-end w-auto sm:w-64">
                        <div className="relative inline-block text-left">
                            {/* <div className="flex items-center" onClick={() => this.props.setDropDown(!this.props.isDropdown)}> */}
                            <Menu>
                                {({ open }) => (
                                    <>
                                        <span className="rounded-md shadow-sm">
                                            <Menu.Button
                                                data-cy="button-menu"
                                                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                            >
                                                <span>{user.firstName}</span>
                                                <svg className="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </Menu.Button>
                                        </span>

                                        <Transition
                                            show={open}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                static
                                                className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                                            >
                                                <div className="px-4 py-3">
                                                    <p className="text-sm leading-5">Signed in as</p>
                                                    <p className="text-sm font-medium leading-5 text-gray-900 truncate">{user.email}</p>
                                                </div>

                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link href="/profile">
                                                                <a
                                                                    data-cy="button-menu-profile"
                                                                    href="#account-settings"
                                                                    className={`${
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                    } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                                                >
                                                                    Account settings
                                                                </a>
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                target="_blank"
                                                                href="https://api.whatsapp.com/send?phone=+31630914938&text=I have a question for otellu"
                                                                className={`${
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                                            >
                                                                Support
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </div>

                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={() => logoutUser()}
                                                                className={`${
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                                                            >
                                                                Sign out
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                )}
                            </Menu>
                        </div>
                    </div>
                )}
            </nav>
        </div>
    );
};

export default Navbar;
