import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import MobileNav from './Nav/mobileNav';
import { StudioNavBar, Navbar } from '../components/Molecules/Navigation';
import Nav from './Nav/Nav';

const Header = (props) => {
    const [isOpenMobile, setOpenMobile] = useState(false);
    const [isOpenSearch, setOpenSearch] = useState(false);
    const [isDropdown, setDropDown] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [sideBarSearchEnabled, setSideBarSearchEnabled] = useState(false);
    const [mobileMenuEnabled, setMobileMenuEnabled] = useState(false);

    //login out
    const logout = () => {
        props.deauthenticate();
        setDropDown(false);
    };

    return props.environment === 'people' ? (
        <Navbar content={props.content} backgroundColor={props.backgroundColor} />
    ) : (
        <StudioNavBar content={props.content} />
    );
};

Header.getInitialProps = async (ctx) => {
    checkServerSideCookie(ctx);
};

// const mapStateToProps = state => ({ isAuthenticated: state.authentication.token, currentUser: state.authentication.currentUser });

// export default connect(
//   mapStateToProps,
//   { deauthenticate }
// )(Header);
export default Header;

export async function getServerSideProps(context) {
    const static_content = await client.getByUID('static', 'static', { lang: 'en-US' });
    return {
        props: {
            static_content,
        },
    };
}
