import React, { useState } from 'react';
import { RichText } from 'prismic-reactjs';
import Link from 'next/link';
import Button from '../Buttons';
import Hamburger from './Hamburger';
import { cdn_url } from '../../helpers/url';

class Nav extends React.Component {
    render() {
        const header_content = this.props.content.data.body.find((x) => x.slice_type === 'header');
        return (
            <div className="flex flex-col relative border-b">
                <nav className="container mx-auto flex items-center justify-between bg-white z-10 py-4 px-4 relative" id="header">
                    <div className="flex items-center w-full justify-between md:w-64">
                        <Link href="/">
                            <img className="fill-current h-6 mr-2 cursor-pointer" src="/assets/logo/otellu-logo.svg" alt="Otellu" />
                        </Link>
                        <div className="md:hidden flex">
                            <img className="mr-4" src="/assets/icons/ic-search-black.svg" alt="dropdown" onClick={this.props.openSearch} />
                            <Hamburger toggleMobileMenu={this.props.openMobileMenu} mobileMenuEnabled={this.props.mobileMenuEnabled} />
                        </div>
                    </div>

                    <div className="text-lg items-center justify-center flex-wrap hidden md:flex">
                        <Link href="/">
                            <a href="/" className="block mt-0 lg:inline-block text-gray-800 hover:text-purple-600 mr-8">
                                {RichText.asText(header_content.primary.menu_home)}
                            </a>
                        </Link>

                        <Link href="/about">
                            <a className="block mt-0 lg:inline-block text-gray-800 hover:text-purple-600 mr-8">
                                {RichText.asText(header_content.primary.menu_about)}
                            </a>
                        </Link>
                        {this.props.isBusiness ? (
                            <Link href="/">
                                <a className="block mt-0 lg:inline-block text-gray-800 hover:text-purple-600">
                                    {RichText.asText(header_content.primary.menu_employee)}
                                </a>
                            </Link>
                        ) : (
                            <Link href="/business">
                                <a className="block mt-0 lg:inline-block text-gray-800 hover:text-purple-600">
                                    {RichText.asText(header_content.primary.menu_employer)}
                                </a>
                            </Link>
                        )}
                    </div>
                    {this.props.isAuthenticated ? (
                        <div className="hidden md:flex justify-end w-auto sm:w-64">
                            <div className="flex items-center" onClick={() => this.props.setDropDown(!this.props.isDropdown)}>
                                <p className="mr-4">{this.props.currentUser.firstName}</p>
                                <img
                                    className="w-10 h-10 rounded-full border-2 border-f2d-purple-800 cursor-pointer"
                                    src={`${cdn_url}/avatar/${this.props.currentUser._id}`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'https://api.adorable.io/avatars/285/abott@adorable.png';
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="hidden md:flex justify-end w-auto">
                            <>
                                {this.props.isBusiness ? (
                                    <a
                                        href="https://studio.otellu.work/register"
                                        className="bg-transparent hover:bg-f2d-purple-800 text-f2d-purple-800 font-semibold hover:text-white py-2 px-8 border border-f2d-purple-800 hover:border-transparent rounded mr-4"
                                    >
                                        {RichText.asText(header_content.primary.menu_register)}
                                    </a>
                                ) : (
                                    <Link href="/signup">
                                        <Button text={RichText.asText(header_content.primary.menu_register)} theme="btn btn-black" />
                                    </Link>
                                )}

                                {this.props.isBusiness ? (
                                    <a
                                        href="https://studio.otellu.work"
                                        target="_blank"
                                        className="flex align-center bg-f2d-purple-800 text-white font-bold py-2 px-8 rounded hover:bg-gray-800 border border-f2d-purple-800 hover:text-f2d-purple-800"
                                    >
                                        {RichText.asText(header_content.primary.menu_login)}
                                    </a>
                                ) : (
                                    <Link href="/login">
                                        <Button text={RichText.asText(header_content.primary.menu_login)} theme="btn btn-outline" extraclassName="ml-4" />
                                    </Link>
                                )}
                            </>
                        </div>
                    )}
                </nav>

                {this.props.isDropdown ? (
                    <div className="absolute z-10 right-0 mt-2 mr-4 py-2 w-48 bg-white rounded-lg shadow-xl">
                        <Link href="/profile">
                            <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">
                                View profile
                            </a>
                        </Link>
                        <a href="#" onClick={this.props.logout} className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">
                            Logout
                        </a>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Nav;
